import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor as AngularHttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class HttpInterceptor implements AngularHttpInterceptor {
  private basicAuthEndpoints: { endpoint: string; user: string }[] = [
    {
      endpoint: "/tokens/:tokenId",
      user: "get-token",
    },
    {
      endpoint: "/auth/reset-password",
      user: "reset-password",
    },
    {
      endpoint: "/registry",
      user: "get-registry",
    },
    {
      endpoint: "/workouts/:workoutId/routes",
      user: "get-workout-route",
    },
  ];
  private readonly basicAuthUsers: Record<string, string>;
  private readonly normalizedRouteRegularExpressions: { regExp: RegExp; replacer: string }[];

  public constructor() {
    this.basicAuthUsers = environment.basicAuthUsers;
    this.normalizedRouteRegularExpressions = [
      { regExp: new RegExp(/\/tokens\/[^\/]+/), replacer: "/tokens/:tokenId" },
      { regExp: new RegExp(/\/workouts\/[^\/]+\/routes/), replacer: "/workouts/:workoutId/routes" },
    ];
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { apiSuffix } = environment;
    const url = this.getUrl();
    const { url: requestUrl } = request;

    request = request.clone({
      setHeaders: {
        "x-source-system": "website",
      },
    });

    let normalizedEndpoint = requestUrl.slice(requestUrl.lastIndexOf(apiSuffix) + apiSuffix.length);
    for (const expression of this.normalizedRouteRegularExpressions) {
      normalizedEndpoint = normalizedEndpoint.replace(expression.regExp, expression.replacer);
    }

    const basicAuthEndpoint = this.basicAuthEndpoints.find((endpoint) => endpoint.endpoint === normalizedEndpoint);
    if (requestUrl.startsWith(url) && basicAuthEndpoint) {
      const { user } = basicAuthEndpoint;
      const password = this.basicAuthUsers[user];

      const basicAuthHeader = btoa(`${user}:${password}`);
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${basicAuthHeader}`,
        },
      });
    }

    return next.handle(request);
  }

  private getUrl() {
    const { workoutPlusUrl, apiSuffix } = environment;
    return workoutPlusUrl + apiSuffix;
  }
}
