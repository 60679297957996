import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "privacy",
    loadChildren: () => import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
  {
    path: "data-protection",
    loadChildren: () =>
      import("./pages/data-protection/data-protection.module").then((m) => m.DataProtectionPageModule),
  },
  {
    path: "reset-password",
    loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordPageModule),
  },
  {
    path: "workout-route",
    loadChildren: () => import("./pages/workout-route/workout-route.module").then((m) => m.WorkoutRoutePageModule),
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
