// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  workoutPlusUrl: "http://localhost:16000/",
  // workoutPlusUrl: "https://dev.workout-plus.de/",
  apiSuffix: "api/v1",
  basicAuthUsers: {
    "get-token": "Jpr6OXt3OHbQSiMXo7kBqmfK",
    "reset-password": "Yzb1cxg916BqOjHMFnrWvj8Z",
    "get-registry": "rvSGVYb1hqeNf5Bq8x5Gp1Wn",
    "get-workout-route": "5oHrqQWgRFUaZSJzOH1n8lj3",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
